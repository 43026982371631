
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap");

@import "stb-variables";
// Bootstrap mixins and functions
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";
@import "app";
@import "mini";

// Change variables here
@import "volt/variables";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// Vendor
@import "volt/vendor";

// volt mixins & functions
@import "volt/mixins";
@import "volt/functions";

// Utilities
@import "volt/reboot";
@import "volt/utilities";

// Layout
@import "volt/layout";

// Components
@import "volt/components";

// write your custom styles here!

.f-sb{
  font-weight: $font-weight-semi-bold;
}

// .inputRounded .MuiOutlinedInput-root {
//   border-radius: 8px;
//   height: 40px;
// }

.dateInput .rs-picker-toggle{
  border-radius: 8px !important;
  border: 1px solid #D0D5DD !important;
  color: #101828;
}

.dateInput .rs-picker-toggle span {
  color: #101828;
}


.row-text-light {
  color:#667085;
  font-weight: $font-weight-semi-bold;
}

.search .form-control::placeholder,.search .form-control,.dropdown .btn-primary, .dropdown .dropdown-item  {
  color:#101828 !important;
}

.dropdown .btn-primary {
  background-color: white !important;
  // color: #667085 !important;
  border: 1px solid #D0D5DD !important;
  border-radius: 8px !important;
  font-weight: 400 !important;
  box-shadow: none !important;
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropdown .dropdown-item {
  // color: #667085 !important;
  font-weight: 400 !important;
}



.search .form-control {
  height: 42px !important;
}


