/**
 * = Input groups
 */

.input-group {
	border-radius: $input-border-radius;
	transition: $transition-base;

	.form-control {
		box-shadow: none;

		&:not(:first-child) {
		    	border-left: 0;
		    	padding-left: 0;
		}
		&:not(:last-child) {
			border-right: 0;
			padding-right: 0;
		}
		&:focus {
			box-shadow: none;
		}
	}
}

.input-group-text {
	font-size: $font-size-sm;
	transition: $input-transition;
	border-right:0;
}



.input-group-prepend {
	margin-right: -2px;
}

// .focus class is applied dinamycally from theme.js

.focused {

	.input-group-text {
	    	color: $input-group-addon-focus-color;
	    	background-color: $input-group-addon-focus-bg;
	}

	.input-group-shadow{
		box-shadow:$shadow-input-focus;
	}
}


// .react-tel-input .flag-dropdown.open .selected-flag {
// 	background: transparent !important;
// }

// .react-tel-input .special-label {
// 	color: #979797 !important;
// }

.phone-container {
	height: 56px;
	border-radius: 8px;
	border: 1px solid rgba(0, 0, 0, 0.23);
	&:focus {
		border: 1px solid #0B0523,
	}

	&:hover {	
		border: 1px solid #0B0523,
	}
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 button {
  border-radius: 8px !important;
	border: 1px solid #0B0523 !important;
  height:56px;
}

.MuiAutocomplete-clearIndicator {
	display: none !important;
	// visibility: hidden !important;
}

.MuiOutlinedInput-root {
	border-radius: 8px !important;
}



.MuiInputLabel-outlined.MuiInputLabel-marginDense {
  transform: translate(14px, 17px) scale(1) !important;
}

.split-left {
	.MuiOutlinedInput-root {
		border-radius: 8px 0 0 8px !important;
	}
}

.split-right {
	.MuiOutlinedInput-root {
		border-radius: 0 8px 8px 0 !important;
	}
}

.MuiSelect-outlined {
	min-height: 0 !important;
}