$stb-primary-color:#FCBD00;
$stb-primary-color-light:#FDD354;
$stb-primary-color-dark:#B38600;

$stb-secondary-color:#0B0523;
$stb-secondary-color-light:#d5cef0;

$stb-blue:#003bbbdc;
$stb-green:#00AB55;
$stb-grey:#667085;

$stb-bg-yellow:#FFF7CD;
$stb-bg-blue:#D0F2FF;
$stb-bg-green:#C8FACD;
$stb-bg-red:#FFE7D9;

$stb-text-yellow:rgb(122, 79, 1);
$stb-text-blue:rgb(4, 41, 122);
$stb-text-green:rgb(0, 82, 73);
$stb-text-red:rgb(124,15,48);


$stb-link-color:#001fcc;
$stb-border-color:#EAECF0;

$stb-s-4: 4px;
$stb-s-8: 8px;
$stb-s-10: 10px;
$stb-s-12: 12px;
$stb-s-14: 14px;
$stb-s-16: 16px;
$stb-s-18: 18px;
$stb-s-20: 20px;

